import {BrowserRouter, Routes, Route} from 'react-router-dom';
import '@fontsource/roboto/400.css';
import './bootstrap-grid.min.css';
import Register from './components/Register';
import Login from './components/Login';
import Logout from './components/Logout';

function App() {
  return (
    <div>
        <BrowserRouter>
          <Routes>
            <Route index element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/logout" element={<Logout />} />

          </Routes>
        </BrowserRouter>

    </div>
  );
}

export default App;